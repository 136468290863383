import { useHistory } from 'react-router-dom';
import { JSON_DATA, JSON_TEXT } from '../../constants';
import Logo from '../../assets/images/logo.png';
import Button from '../../components/Button';
import VideoPlayer from '../../components/VideoPlayer';
import { useLang } from '../../contexts/LangContext';
import { useLangQuery } from '../../hooks/useLangQuery';
const Home = () => {
  const history = useHistory();
  const langContext = useLang();

  // Note: not sure if this is the optimal way of reusing useEffect
  useLangQuery();

  // do not load if langContext is undefined
  if (!langContext) {
    return null;
  }
  const textData = JSON_TEXT[langContext.lang];

  return (
    <div className="home">
      <div className="home-video-bg">
        <VideoPlayer path={process.env.PUBLIC_URL + JSON_DATA.home.videoBg} />
      </div>
      <div className="home-left-panel">
        <div className="home-detail-section">
          <div className="home-logo">
            <img src={Logo} alt={textData.general.companyName} />
          </div>
          <h1>{textData.home.title}</h1>
          <div className="under"></div>
          <div
            className="home-description"
            dangerouslySetInnerHTML={{
              __html: textData.home.description
            }}
          ></div>
          <div className="home-link-button">
            <Button
              onClick={() => history.push('/view' + history.location.search)}
              text={textData.general.exploreCta}
              width="300px"
              height="90px"
              isBold={true}
              backgroundColor="#01a982"
              borderSize="0"
              textSize="40px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
