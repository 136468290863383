import { useHistory } from 'react-router-dom';
import VideoPlayer from '../VideoPlayer';
import Button from '../Button';
import { JSON_TEXT } from '../../constants';
import { useLang } from '../../contexts/LangContext';

type ViewCardProps = {
  videoPath: string;
  linkPath: string;
  vertical: string;
  description: string;
};

const ViewCard = ({
  videoPath,
  linkPath,
  vertical,
  description
}: ViewCardProps) => {
  const history = useHistory();
  const langContext = useLang();
  if (!langContext) {
    return null;
  }
  const textData = JSON_TEXT[langContext.lang];

  return (
    <div className="view-card">
      <div className="view-video">
        <VideoPlayer
          path={process.env.PUBLIC_URL + videoPath}
          isBackground={false}
        />
      </div>
      <div className="view-card-bottom">
        <h4>{vertical}</h4>
        <div className="break" />
        <h3>{description}</h3>
        <div className="view-card-link-button">
          <Button
            onClick={() => history.push(linkPath + history.location.search)}
            text={textData.general.exploreCta}
            width="198px"
            height="64px"
            backgroundColor="#01a982"
            isBold={true}
            textSize="30px"
            borderSize="1px"
          />
        </div>
      </div>
    </div>
  );
};

export default ViewCard;
