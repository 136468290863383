import { useState, useEffect } from 'react';
import { Volume, VolumeMute } from 'grommet-icons';
import { JSON_DATA } from '../../constants';
import ReactAudioPlayer from 'react-audio-player';

type AudioPlayerProps = {
  soundId?: string;
  soundFillerId?: string;
  isMute: boolean;
  onClick: (any: any) => void;
};

const AudioPlayer = ({
  soundId,
  soundFillerId,
  isMute,
  onClick
}: AudioPlayerProps) => {
  const [soundFile, setSoundFile] = useState('');
  const [soundFillerFile, setSoundFillerFile] = useState('');
  const volumeSetting = 1; // Default sound setting
  // console.log('[AudioPlayer] isMute soundId', isMute, soundId, soundFillerId);

  // Set sound file
  useEffect(() => {
    if (soundId) {
      setSoundFile(process.env.PUBLIC_URL + JSON_DATA.audio[soundId]);
    }
  }, [soundId]);

  // Set sound filler file
  useEffect(() => {
    if (soundFillerId) {
      setSoundFillerFile(
        process.env.PUBLIC_URL + JSON_DATA.audio[soundFillerId]
      );
    }
  }, [soundFillerId]);

  // Audio for button press
  const buttonPressAudio = new Audio(
    process.env.PUBLIC_URL + JSON_DATA.audio.buttonPress
  );

  const buttonClick = (e: any) => {
    buttonPressAudio.play();
    console.log('[AudioPlayer] buttonClick soundFile', soundFile);
    console.log('[AudioPlayer] buttonClick soundFillerFile', soundFillerFile);
    if (onClick !== null) {
      onClick(e);
    }
  };

  return (
    <div>
      <div onClick={buttonClick}>
        {isMute ? <VolumeMute size="large" /> : <Volume size="large" />}
      </div>
      <ReactAudioPlayer
        src={soundFile}
        muted={isMute}
        volume={volumeSetting}
        controls={false}
        autoPlay={true}
        loop={true}
        preload="auto"
      />
      <ReactAudioPlayer
        src={soundFillerFile}
        muted={isMute}
        volume={volumeSetting}
        controls={false}
        autoPlay={true}
        loop={true}
        preload="auto"
      />
    </div>
  );
};

export default AudioPlayer;
