import { ReactNode, createContext, useState, useContext } from 'react';

type playStateType = 'play' | 'pause';

type PlayStateProviderProps = { children: ReactNode };
type playStateContextType = {
  playState: playStateType;
  setState: (state: playStateType) => void;
};

const PlayStateContext = createContext<playStateContextType | undefined>(
  undefined
);

const PlayStateProvider = ({ children }: PlayStateProviderProps) => {
  const initialState: playStateType = 'play';

  const [state, setState] = useState<playStateType>(initialState);

  return (
    <PlayStateContext.Provider value={{ playState: state, setState }}>
      {children}
    </PlayStateContext.Provider>
  );
};

const usePlayState = () => useContext(PlayStateContext);

export { PlayStateProvider, usePlayState };
