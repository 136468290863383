import { useEffect, useState, CSSProperties } from 'react';
import { JSON_DATA, JSON_TEXT } from '../../constants';
import { useLang } from '../../contexts/LangContext';
import { Checkmark } from 'grommet-icons';

type SolutionLabelProps = {
  deploymentSelection?: any;
  verticalId: string;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  color?: string;
};

// Helper to chose random
// https://www.tutorialspoint.com/javascript-how-to-pick-random-elements-from-an-array
const chooseRandom = (arr: any) => {
  let num = Math.floor(Math.random() * arr.length);
  if (num === 0) {
    num = 4; // Force to four for aesthetics
  }
  // console.log('num', num);
  const res = [];
  for (let i = 0; i < num; ) {
    const random = Math.floor(Math.random() * arr.length);
    if (res.indexOf(arr[random]) !== -1) {
      continue;
    }
    res.push(arr[random]);
    i++;
  }
  return res;
};

const SolutionLabel = ({
  deploymentSelection,
  verticalId,
  x = 0,
  y = 0,
  width = 200,
  height = 100
}: SolutionLabelProps) => {
  const [storeName, setStoreName] = useState<boolean>(false);
  const [finalScenes, setFinalScenes] = useState<any[]>([]);

  const langContext = useLang();
  const textData = langContext ? JSON_TEXT[langContext.lang] : undefined;
  const solution = textData.solution;
  const challenges = JSON_DATA.vertical[verticalId].challenges;

  useEffect(() => {
    if (langContext) {
      const scalePrefix =
        JSON_TEXT[langContext.lang].vertical[verticalId].scalePrefix;

      const min = 360; // Arbitrary minimum value
      const max = 1250; // Arbitrary maximum value
      const randomValue = Math.floor(Math.random() * (max - min + 1) + min);

      setStoreName(scalePrefix + randomValue);
    }
  }, [verticalId, langContext]);

  useEffect(() => {
    // Look through scenes to get all valid solutions
    const allScenes: string[] = [];
    challenges.forEach((key: any) => {
      const thisScene = JSON_DATA.scene[key].solutions;
      allScenes.push(...thisScene);
    });

    // Remove duplicates
    const uniqueScenes: string[] = [];
    allScenes.forEach((c) => {
      if (!uniqueScenes.includes(c)) {
        uniqueScenes.push(c);
      }
    });
    // Final scenes to show
    let finalScenes: string[] = [];

    // Check if deploymentSelection was provided (passed through state)
    if (deploymentSelection && deploymentSelection.length > 0) {
      // Exists: Set this first value to be those values
      const selectedScenes: string[] = [];
      deploymentSelection.forEach(({ key }: { key: string }) => {
        selectedScenes.push(key);
      });
      finalScenes = selectedScenes;
    } else {
      // Not exist: Return random list of valid scenes
      // TODO: Unsure why it randomizes on click...
      finalScenes = chooseRandom(uniqueScenes);
    }
    finalScenes.sort();
    setFinalScenes(finalScenes.sort());
  }, [deploymentSelection, challenges]);

  const xPosition: string = Math.round(x - width / 2) + 'px';
  const yPosition: string = Math.round(y - height / 2) + 'px';
  const thisWidth: string = width + 'px';
  const style: CSSProperties = {
    // border: '1px solid blue',
    position: 'absolute',
    left: xPosition,
    top: yPosition,
    width: thisWidth,
    // height: height,
    zIndex: 1
  };

  // if langContext is undefined do not render
  if (!langContext) {
    return null;
  }

  return (
    <div className="solution-label" style={style}>
      <h2>{storeName}</h2>
      <ul>
        {finalScenes &&
          finalScenes.map((key: any) => {
            return (
              <li key={key}>
                <Checkmark />
                <label>{solution[key].title}</label>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default SolutionLabel;
