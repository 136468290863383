import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ScrollContainer from 'react-indiana-drag-scroll';
import { JSON_DATA, JSON_TEXT } from '../../constants';
import { ViewDataType } from '../../data/types';
import Button from '../../components/Button';
import ViewCard from '../../components/ViewCard';
import { getLang } from '../../utils/getLang';
import { useLang } from '../../contexts/LangContext';

const View = () => {
  const history = useHistory();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const queryLang = values.lang;
  const langContext = useLang();

  // do not load if langContext is undefined
  if (!langContext) {
    return null;
  }

  const viewList = JSON_DATA.view;
  if (viewList.length === 0) {
    // Force back to main screen if no view
    history.push('/' + history.location.search);
  }
  if (viewList.length === 1) {
    // If single, just auto advance
    history.push(JSON_DATA.view[0].link + history.location.search);
  }

  const lang: string = getLang(queryLang);
  return (
    <div className="view">
      <div className="view-back-button">
        <Button
          text={JSON_TEXT[langContext.lang].general.back}
          color="black"
          backgroundColor="transparent"
          width="140px"
          height="79px"
          textSize="80px"
          borderSize="0"
          onClick={() => history.push('/' + history.location.search)}
        />
      </div>
      <div className="view-cards">
        <ScrollContainer className="view-card-row">
          {JSON_DATA.view.map((data: ViewDataType, index: number) => {
            // TODO look at id loop up possibility instead of index
            const description: string = JSON_TEXT[lang].view[index].description;
            const vertical: string = JSON_TEXT[lang].view[index].vertical;

            return (
              <div className="view-card-container" key={'view-card-' + index}>
                <ViewCard
                  videoPath={data.videoBg}
                  linkPath={data.link}
                  description={description}
                  vertical={vertical}
                />
              </div>
            );
          })}
        </ScrollContainer>
      </div>
    </div>
  );
};

export default View;
