import { CSSProperties } from 'react';
import { Checkmark, PlayFill, PauseFill } from 'grommet-icons';
import { SolutionDataType, SolutionTextType } from '../../data/types';
import { JSON_TEXT, JSON_DATA } from '../../constants';
import { useLang } from '../../contexts/LangContext';

type SolutionBoxProps = {
  sceneId: string;
  solutionData: SolutionDataType;
  solutionTextData: SolutionTextType;
  isPlaying: boolean;
  isBuild: boolean;
  togglePlay: (any: any) => void;
};

const SolutionBox = ({
  sceneId,
  solutionData,
  solutionTextData,
  isPlaying,
  isBuild,
  togglePlay
}: SolutionBoxProps) => {
  const langContext = useLang();
  const xPosition: string = solutionData.boxPositionX + 'px';
  const yPosition: string = solutionData.boxPositionY + 'px';
  const width: string = solutionData.boxWidth + 'px';
  const style: CSSProperties = {
    left: xPosition,
    top: yPosition,
    width: width
  };

  if (!langContext) {
    return null;
  }
  const textData = JSON_TEXT[langContext.lang];
  const solutions = JSON_DATA.scene[sceneId].solutions;
  const isControlVisible = JSON_DATA.scene[sceneId].isControlVisible;

  // Establish build descriptions
  let buildDescriptions = [];
  if (isBuild) {
    buildDescriptions = solutionTextData.build;
  }

  return (
    <div className="solution-box" style={style}>
      {solutionTextData.note && (
        <div
          className="note"
          dangerouslySetInnerHTML={{
            __html: solutionTextData.note
          }}
        />
      )}
      <p
        className="solution-box-desc"
        dangerouslySetInnerHTML={{
          __html: solutionTextData.description
        }}
      ></p>
      {isBuild &&
        buildDescriptions.length > 0 &&
        buildDescriptions.map((key: any, index: number) => {
          const delay = Number(index + 1) + 's';
          return (
            <p
              key={'box-' + index}
              className="solution-box-desc build"
              style={{ animationDelay: delay }}
              dangerouslySetInnerHTML={{
                __html: key.description
              }}
            ></p>
          );
        })}
      <div className="solution-box-footer">
        <div>
          {solutions && <h2>{textData.general.solutionListHeader}</h2>}
          <ul>
            {solutions &&
              solutions.map((key: any) => {
                return (
                  <li key={key}>
                    <div>
                      <Checkmark size="medium" color={'#000000'} />
                    </div>
                    <div>{textData.solution[key].title}</div>
                  </li>
                );
              })}
          </ul>
        </div>
        {isControlVisible && (
          <div className="solution-box-control" onClick={togglePlay}>
            {isPlaying ? <PauseFill /> : <PlayFill />}
          </div>
        )}
      </div>
    </div>
  );
};

export default SolutionBox;
