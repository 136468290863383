// Shows animation + content for chosen solution

import { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SolutionDataType, SolutionTextType } from '../../data/types';
import AnimationPlayerSync from '../../components/AnimationPlayerSync';
import SolutionBox from '../../components/SolutionBox';
import SolutionLabelGroup from '../../components/SolutionLabelGroup';
import DeploymentControl from '../../components/DeploymentControl';

type SolutionProps = {
  verticalId: string;
  sceneId: string;
  solutionData?: SolutionDataType;
  solutionTextData?: SolutionTextType;
  isPlaying: boolean;
  togglePlay: (any: any) => void;
};

const Solution = ({
  verticalId,
  sceneId,
  solutionData,
  solutionTextData,
  isPlaying,
  togglePlay
}: SolutionProps) => {
  const location = useLocation<{ deploymentValues: any; test: string }>();
  const history = useHistory();
  const [deploymentSelection, setDeploymentSelection] = useState<any>([]);
  const [isDeploying, setIsDeploying] = useState<boolean>(false);

  useEffect(() => {
    if (location.state) {
      const { deploymentValues } = location.state || { deploymentValues: null };
      setDeploymentSelection(deploymentValues);
    }
  }, [location]);

  if (!solutionData || !solutionTextData) {
    return null;
  }

  // Show deployment control
  const showDeployment =
    solutionData && solutionData.type && solutionData.type === 'deployment';

  // Show build logic
  const isBuild =
    solutionData && solutionData.type && solutionData.type === 'build';

  const onDeploymentClick = (deploymentValues: any) => {
    setIsDeploying(true);
    const thisTimer = setTimeout(() => {
      clearTimeout(thisTimer);
      setIsDeploying(false); // TODO: Probably not needed if moving to a different page
      if (solutionData.destination) {
        history.push({
          pathname: solutionData.destination,
          state: {
            deploymentValues: deploymentValues
          }
        });
      }
    }, 3000);
  };

  return (
    <Fragment>
      {isBuild && (
        <SolutionLabelGroup
          deploymentSelection={deploymentSelection}
          verticalId={verticalId}
          pause={!isPlaying}
        />
      )}
      {showDeployment && (
        <DeploymentControl
          verticalId={verticalId}
          isDeploying={isDeploying}
          onClick={onDeploymentClick}
        />
      )}
      {isDeploying && <div className="deploying"></div>}

      <div className="vertical-solution-lottie-container">
        <AnimationPlayerSync
          srcPath={solutionData.animationBg}
          loop={true}
          autoplay={true}
          isDebug={false}
          width={solutionData.animationBgWidth}
          height={solutionData.animationBgHeight}
        />
      </div>
      <SolutionBox
        sceneId={sceneId}
        solutionData={solutionData}
        solutionTextData={solutionTextData}
        isPlaying={isPlaying}
        isBuild={Boolean(isBuild)}
        togglePlay={togglePlay}
      />
    </Fragment>
  );
};

export default Solution;
