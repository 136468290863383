import { CSSProperties } from 'react';
import { JSON_DATA } from '../../constants';

type ButtonProps = {
  text: string;
  textSize?: string;
  isBold?: boolean;
  width?: string;
  height?: string;
  color?: string;
  backgroundColor?: string;
  onClick: (any: any) => void;
  borderSize?: string;
  disabled?: boolean;
  isCap?: boolean;
};

const Button = ({
  text,
  textSize,
  isBold = false,
  color = 'white',
  width = '198px',
  height = '64px',
  backgroundColor = 'black',
  onClick,
  borderSize = '0px',
  disabled = false,
  isCap = true
}: ButtonProps) => {
  const style: CSSProperties = {
    fontSize: textSize,
    fontFamily: isBold ? 'MetricHPEBold' : 'MetricHPERegular',
    color: color,
    width: width,
    height: height,
    backgroundColor: backgroundColor,
    border: `${borderSize} solid`,
    textTransform: isCap ? 'uppercase' : 'none',
    boxSizing: 'border-box'
  };

  let audio = new Audio(process.env.PUBLIC_URL + JSON_DATA.audio.buttonPress);
  const buttonClick = (e: any) => {
    audio.play();
    if (onClick !== null) {
      onClick(e);
    }
  };

  return (
    <button
      className="button-custom"
      onClick={buttonClick}
      disabled={disabled}
      style={style}
    >
      {text}
    </button>
  );
};

export default Button;
