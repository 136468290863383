// Wrapper for AnimationPlayer to sync with global playState

import AnimationPlayer, {
  AnimationPlayerProps
} from '../../components/AnimationPlayer';
import { usePlayState } from '../../contexts/PlayStateContext';

const AnimationPlayerSync = ({ ...rest }: AnimationPlayerProps) => {
  const playStateContext = usePlayState();
  const globalPlayState = playStateContext?.playState;

  return (
    <AnimationPlayer
      isPlaying={globalPlayState ? globalPlayState === 'play' : false}
      {...rest}
    />
  );
};

export default AnimationPlayerSync;
