import { CSSProperties, useState } from 'react';
import { JSON_DATA, JSON_TEXT } from '../../constants';
import { useLang } from '../../contexts/LangContext';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';

type DeploymentControlProps = {
  verticalId: string;
  isDeploying: boolean;
  onClick: (any: any) => void;
};

const DeploymentControl = ({
  verticalId,
  isDeploying,
  onClick
}: DeploymentControlProps) => {
  const [deployment, setDeployment] = useState<any>([]);
  const [isDeployDisabled, setIsDeployDisabled] = useState<boolean>(true);

  const langContext = useLang();
  if (!langContext) {
    return null;
  }

  const textData = JSON_TEXT[langContext.lang];
  const solution = textData.solution;
  const challenges = JSON_DATA.vertical[verticalId].challenges;

  // Look through scenes to get all valid solutions
  const allScenes: string[] = [];
  challenges.forEach((key: any) => {
    const thisScene = JSON_DATA.scene[key].solutions;
    allScenes.push(...thisScene);
  });
  // Remove duplicates
  const uniqueScenes: string[] = [];
  allScenes.forEach((c) => {
    if (!uniqueScenes.includes(c)) {
      uniqueScenes.push(c);
    }
  });
  uniqueScenes.sort();

  let color = '#333333';

  let deployButtonText = textData.general.deployControl;
  if (isDeploying) {
    deployButtonText = textData.general.deployProcessControl;
    color = '#cccccc';
  }

  const style: CSSProperties = {
    color: color
  };

  const onOptionClick = ({ title, key }: { title: string; key: string }) => {
    // console.log('onOptionClick text, key', text, key);
    const newDeployment = { key, title };
    let newDeploymentArray: any = [];
    if (deployment.length > 0) {
      // Existing, add to previous
      // newDeploymentArray = deployment.slice(); // Yay, proper way
      let addIn = true; // TODO: Something wrong about this approach

      // Detect existing and update them with new values
      deployment.forEach((result: any, index: number) => {
        if (result.key === key) {
          // console.log('Key exists! Do not add back to new', index);
          addIn = false;
        } else {
          newDeploymentArray.push(result);
        }
      });
      if (addIn) {
        newDeploymentArray.push(newDeployment);
      }
    } else {
      newDeploymentArray.push(newDeployment);
    }

    // Determines if selection was made to activate Deploy button
    if (newDeploymentArray.length === 0) {
      setIsDeployDisabled(true);
    } else {
      setIsDeployDisabled(false);
    }

    setDeployment(newDeploymentArray);
  };

  return (
    <div className="deployment" style={style}>
      <h2 style={style}>{textData.general.chooseDeployment}</h2>
      <div className="deployment-selections">
        {uniqueScenes &&
          uniqueScenes.map((key: any) => {
            // Iterate through deployment to see if key exists
            let isSelected = false;
            if (deployment.filter((e: any) => e.key === key).length > 0) {
              isSelected = true; // Item is found in list
            }
            return (
              <Checkbox
                text={solution[key].title}
                key={key}
                id={key}
                disabled={isDeploying}
                isSelected={isSelected}
                onClick={() => {
                  // console.log('[DeploymentControl] click', key);
                  onOptionClick({
                    title: solution[key].title,
                    key: key
                  });
                }}
              />
            );
          })}
      </div>
      <Button
        disabled={isDeployDisabled || isDeploying}
        text={deployButtonText}
        isBold={true}
        color="white"
        backgroundColor="#01a982"
        width="250px"
        textSize="30px"
        borderSize="0"
        onClick={() => {
          onClick(deployment);
        }}
      />
    </div>
  );
};

export default DeploymentControl;
