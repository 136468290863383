/*
 * AnimationPlayer, wrapper for Lottie animations
 * Inspiration from: https://vojta.io/posts/lottie-animations-in-react-and-mdx/
 * Original react-lottie seems to have been abandoned since 2018.
 * Shifted to @crello/react-lottie but needs to dig into TypeScript codebase for playingState
 */

import { useState, useEffect, CSSProperties } from 'react';
import { Lottie } from '@crello/react-lottie';

export type AnimationPlayerProps = {
  width?: number;
  height?: number;
  srcPath: string;
  loop?: boolean;
  autoplay?: boolean;
  isDebug?: boolean;
  isPlaying?: boolean;
};

const AnimationPlayer = ({
  width,
  height,
  srcPath,
  loop,
  autoplay,
  isDebug,
  isPlaying = true
}: AnimationPlayerProps) => {
  const [animationJson, setAnimationJson] = useState({});
  const [playingState, setPlayingState] = useState('playing');

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}` + srcPath)
      .then((r) => r.json())
      .then((data) => {
        setAnimationJson(data);
      })
      .catch((error) => {
        console.log('JSON ERROR for path ' + srcPath + ' ERROR: ' + error);
      });
  }, [srcPath]);

  // set playing
  useEffect(() => {
    if (isPlaying) {
      setPlayingState('playing');
    } else {
      setPlayingState('paused');
    }
  }, [isPlaying]);

  const speed = 1;
  const configOptions = {
    loop: loop || false,
    autoplay: autoplay || false,
    animationData: animationJson
  };
  const debugStyle: CSSProperties = {
    border: '1px solid red'
  };
  const debugPlacementStyle: CSSProperties = {
    position: 'absolute',
    bottom: '10px',
    left: '10px'
  };
  const buttonStyle = { margin: '10px 10px 0 0' };

  return Object.keys(animationJson).length !== 0 ? (
    <div style={isDebug ? debugStyle : {}}>
      <Lottie
        width={width || '100%'}
        height={height || '100%'}
        config={configOptions}
        playingState={playingState}
        speed={speed}
      />
      {isDebug && (
        <div style={debugPlacementStyle}>
          <small>playState = {playingState}</small>
          <br />
          <button
            onClick={() => setPlayingState('playing')}
            style={buttonStyle}
          >
            Play
          </button>
          <button onClick={() => setPlayingState('paused')} style={buttonStyle}>
            Pause
          </button>
          <button
            onClick={() => setPlayingState('stopped')}
            style={buttonStyle}
          >
            Stop
          </button>
        </div>
      )}
    </div>
  ) : (
    <div></div>
  );
};

export default AnimationPlayer;
