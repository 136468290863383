import { JSON_DATA, JSON_TEXT } from '../../constants';
import { SolutionDataType } from '../../data/types';
import { useLang } from '../../contexts/LangContext';

type NavMenuProps = {
  verticalTextData: any;
  solutions: string[];
  challenges: string[];
  onClick: (any: any) => void;
  selectedSolutionKey?: string;
  solutionKeyValid?: boolean;
  disabled?: boolean;
};

const NavMenu = ({
  verticalTextData,
  solutions,
  challenges,
  onClick,
  selectedSolutionKey,
  disabled
}: NavMenuProps) => {
  const langContext = useLang();
  if (!langContext) {
    return null;
  }
  const textData = JSON_TEXT[langContext.lang];
  let audio = new Audio(process.env.PUBLIC_URL + JSON_DATA.audio.buttonPress);

  return (
    <div className="nav-menu">
      <h1>
        {verticalTextData.title}
        {verticalTextData.subtitle && <span>{verticalTextData.subtitle}</span>}
      </h1>
      {verticalTextData.description && (
        <p className="description">{verticalTextData.description}</p>
      )}
      <p className="cta">{textData.general.menuCta}</p>
      <h2>{textData.general.challengeHeader}</h2>
      <div className="nav-menu-container">
        {challenges.map((key) => {
          const solutionObj: SolutionDataType = JSON_DATA.scene[key];
          const isActive = selectedSolutionKey === key;
          return (
            <div
              title={textData.general.viewChallenge}
              className={`nav-menu-box ${isActive ? 'active' : ''}`}
              key={'nav-menu-challenge-' + key}
              onClick={() => {
                if (!isActive) {
                  if (!disabled) {
                    audio.play();
                    onClick({
                      link: solutionObj.link,
                      key: key
                    });
                  }
                }
              }}
            ></div>
          );
        })}
      </div>

      <h2>{textData.general.solutionHeader}</h2>
      <div className="nav-menu-container">
        {solutions.map((key) => {
          const solutionObj: SolutionDataType = JSON_DATA.scene[key];
          const isActive = selectedSolutionKey === key;
          return (
            <div
              title={textData.general.viewSolution}
              className={`nav-menu-box ${isActive ? 'active' : ''}`}
              key={'nav-menu-solution-' + key}
              onClick={() => {
                if (!isActive) {
                  if (!disabled) {
                    audio.play();
                    onClick({
                      link: solutionObj.link,
                      key: key
                    });
                  }
                }
              }}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default NavMenu;
