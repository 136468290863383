// updates context lang from url query
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { getLang } from '../utils/getLang';
import { useLang } from '../contexts/LangContext';

const useLangQuery = () => {
  const { search } = useLocation();
  const langContext = useLang();
  useEffect(() => {
    const values = queryString.parse(search);
    const queryLang = values.lang;

    // get lang query
    const langQuery: string = getLang(queryLang);
    const lang = langContext?.lang;

    // compare with provider lang
    if (lang && lang !== langQuery) {
      // different so update
      langContext?.setLang(langQuery);
    }
  }, [langContext, search]);
};

export { useLangQuery };
