import { CSSProperties } from 'react';
import styled, { keyframes } from 'styled-components';

type StatisticProps = {
  content: string;
  x: number;
  y: number;
  width?: number;
  height?: number;
  color?: string;
  total: number;
  count: number;
};

type LabelProps = {
  delay: string;
  duration: string;
};

// Logic on wait cycle from: https://waitanimate.wstone.io/
const labelCycleWait = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  12.5% {
    transform: translateX(20px);
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  37.5% {
    opacity: 1;
  }
  50% {
    transform: translateX(0px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }`;

const StyledStatistic = styled.div<LabelProps>`
  position: absolute;
  z-index: 100;
  opacity: 0;
  animation-name: ${labelCycleWait};
  animation-timing-function: ease-in-out;
  animation-duration: ${(prop) => prop.duration};
  animation-iteration-count: infinite;
  animation-delay: ${(prop) => prop.delay};
`;

const Statistic = ({
  content,
  x,
  y,
  width = 100,
  height = 100,
  total,
  count
}: StatisticProps) => {
  const xPosition: string = Math.round(x - width / 2) + 'px';
  const yPosition: string = Math.round(y - height / 2) + 'px';
  const thisWidth: string = width + 'px';
  const style: CSSProperties = {
    position: 'absolute',
    left: xPosition,
    top: yPosition,
    width: thisWidth,
    zIndex: 1
  };

  const delay = count * 2 + 's';
  const duration = total * 2 + 's';

  return (
    <StyledStatistic delay={delay} duration={duration}>
      <div
        style={style}
        dangerouslySetInnerHTML={{
          __html: content
        }}
      ></div>
    </StyledStatistic>
  );
};

export default Statistic;
