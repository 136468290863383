import ReactPlayer from 'react-player';
import { useEffect } from 'react';
import { usePlayState } from '../../contexts/PlayStateContext';

type VideoPlayerProps = {
  path: string;
  /**
   * Fill video screen to 16:9 aspect ratio
   */
  isBackground?: boolean;
  isPlaying?: boolean;
  loop?: boolean;
  onEnded?: () => void;
  posterPath?: string;
};

const VideoPlayer = ({
  path,
  isBackground = true,
  isPlaying = true,
  loop = true,
  onEnded,
  posterPath
}: VideoPlayerProps) => {
  const playStateContext = usePlayState();
  const globalPlayState = playStateContext?.playState;
  const isMute = true; // Force mute

  useEffect(() => {
    // Detected different path, pause global playState till ready
    if (
      playStateContext?.playState &&
      playStateContext?.playState !== 'pause'
    ) {
      // if diff update
      // NOTE this helps lottie in sync on video change
      playStateContext?.setState('pause');
    }
  }, [path]); // eslint-disable-line react-hooks/exhaustive-deps

  const onPlay = () => {
    //compare with global playState
    if (globalPlayState && globalPlayState !== 'play') {
      // if diff update
      playStateContext?.setState('play');
    }
  };

  const onPause = () => {
    //compare with global playState
    if (globalPlayState && globalPlayState !== 'pause') {
      // if diff update
      playStateContext?.setState('pause');
    }
  };

  return (
    <div className="video-player">
      <div className={isBackground ? 'wrapper' : ''}>
        <ReactPlayer
          className={isBackground ? 'player-bg' : 'player'}
          url={path}
          width="100%"
          height="100%"
          playing={isPlaying}
          loop={loop}
          volume={1}
          muted={isMute}
          onPlay={onPlay}
          onPause={onPause}
          onEnded={onEnded}
          config={{ file: { attributes: { poster: posterPath } } }}
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
