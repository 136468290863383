import { JSON_TEXT } from '../constants';

export const getLang = (queryLang: string | string[] | null) => {
  // default lang is en
  let finalLang: string = 'en';

  if (
    queryLang &&
    !Array.isArray(queryLang) &&
    JSON_TEXT.hasOwnProperty(queryLang)
  ) {
    finalLang = queryLang;
  }
  return finalLang;
};
