import { ReactNode, createContext, useReducer, useContext } from 'react';

type Action =
  | { type: 'CHANGE_LANG'; payload: string }
  | { type: 'DEFAULT'; payload: string };
//type Dispatch = (action: Action) => void;
type State = { lang: string };
type LangProviderProps = { children: ReactNode };
type langContextType = {
  lang: string;
  setLang: (lang: string) => void;
};

const langReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'CHANGE_LANG': {
      return { lang: action.payload };
    }
    case 'DEFAULT': {
      return { lang: 'en' };
    }
    default: {
      throw new Error(`Unhandled action type}`);
    }
  }
};

const LangContext = createContext<langContextType | undefined>(undefined);

const LangProvider = ({ children }: LangProviderProps) => {
  const initialState = {
    lang: 'en'
  };

  const [state, dispatch] = useReducer(langReducer, initialState);
  const setLang = (newLang: string) => {
    dispatch({ type: 'CHANGE_LANG', payload: newLang });
  };

  return (
    <LangContext.Provider value={{ lang: state.lang, setLang }}>
      {children}
    </LangContext.Provider>
  );
};

const useLang = () => useContext(LangContext);

export { LangProvider, useLang };
