// Video player with mask

import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import VideoPlayer from '../../components/VideoPlayer';

interface Props {
  videoBgSrcPath: string;
  posterPath: string;
  interstitial?: string;
  isMute?: boolean;
  isBackground?: boolean;
  isPlaying?: boolean;
  onEnded: () => void;
}

const ANIMATION_TIME = 2;

const wipe = keyframes`
  0% {
    mask-position: -500px; // NOTE perhaps change the gradient to cover the whole width
    mask-size: 1920px 1080px;
  }
  100% {
    //mask-position: 1920px; // NOTE changing position still shows the repeated mask.
    mask-size: 12000px 1080px; // NOTE changing width size to mimic gradient moving left to right without the mask repeating
  }`;

const MaskStyle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 1920px;
  max-height: 1080px;
  overflow: hidden;
  animation-play-state: running;
  animation: ${wipe};
  animation-duration: ${ANIMATION_TIME}s;
  animation-delay: 0s;
  animation-timing-function: ease-out;
  mask-repeat: no-repeat; // Note mask seems to repeat when moving position?
  mask-image: -webkit-gradient(
    // NOTE Needs better gradient structure
    linear,
    left top,
    right bottom,
    color-stop(0, rgba(0, 0, 0, 1)),
    color-stop(0.4, rgba(0, 0, 0, 1)),
    color-stop(0.5, rgba(0, 0, 0, 0)),
    color-stop(0.6, rgba(0, 0, 0, 0)),
    color-stop(1, rgba(0, 0, 0, 0))
  );
`;

const VideoHelper = ({
  videoBgSrcPath,
  posterPath,
  interstitial,
  isMute,
  isPlaying = true,
  onEnded
}: Props) => {
  const [showMask, setShowMask] = useState<boolean>(false);
  const [videoPath, setVideoPath] = useState<string>('');
  const [nextPosterPath, setNextPosterPath] = useState<string>('');

  /* old way using interstitial
  useEffect(() => {
    // src path of background video to play
    let bgVideoSrcPath = interstitial !== '' ? interstitial : videoBgSrcPath;

    setVideoPath(bgVideoSrcPath);
  }, [interstitial, videoBgSrcPath]);
  */

  useEffect(() => {
    if (posterPath !== '') {
      setNextPosterPath(posterPath);
      setShowMask(true);
    } else {
      setVideoPath(videoBgSrcPath);
    }
  }, [posterPath, videoBgSrcPath]);

  useEffect(() => {
    if (nextPosterPath !== '') {
      let timer = setTimeout(() => {
        setVideoPath(videoBgSrcPath);
      }, ANIMATION_TIME * 800);

      // wait bit longer for hiding mask to have video loaded
      let timer2 = setTimeout(() => {
        setShowMask(false);
        // call onEnded to set interstitial/posterPath to ''
        onEnded();
      }, (ANIMATION_TIME + 1) * 800);

      return () => {
        clearTimeout(timer);
        clearTimeout(timer2);
      };
    }
  }, [nextPosterPath, videoBgSrcPath, onEnded]);

  // NOTE if using the old way of interstitial, make sure to change loop condition for VideoPlayer
  return (
    <div>
      <VideoPlayer
        path={process.env.PUBLIC_URL + videoPath}
        isPlaying={isPlaying}
        onEnded={onEnded}
        loop={true}
        posterPath={nextPosterPath}
      />
      {showMask && (
        <MaskStyle>
          <img src={process.env.PUBLIC_URL + nextPosterPath} alt="poster" />
        </MaskStyle>
      )}
    </div>
  );
};

export default VideoHelper;
