import { CSSProperties } from 'react';
import { JSON_DATA } from '../../constants';
import {
  CheckboxSelected as CheckboxSelectedIcon,
  Checkbox as CheckboxIcon
} from 'grommet-icons';

type CheckboxProps = {
  id: string;
  text: string;
  isSelected?: boolean;
  color?: string;
  onClick: (any: any) => void;
  disabled?: boolean;
};

const Checkbox = ({
  id,
  text,
  isSelected = false,
  color = '#333333',
  onClick,
  disabled = false
}: CheckboxProps) => {
  let audio = new Audio(process.env.PUBLIC_URL + JSON_DATA.audio.checkPress);

  const checkboxPress = (e: any) => {
    audio.play();
    if (onClick !== null) {
      onClick(e);
    }
  };

  let labelClass = 'checkbox';

  if (disabled) {
    color = '#cccccc';
    labelClass = labelClass + ' disabled';
  }
  const style: CSSProperties = {
    color: color
  };

  return (
    <label
      className={labelClass}
      onClick={disabled ? () => {} : checkboxPress}
      id={id}
    >
      {isSelected ? (
        <CheckboxSelectedIcon color={color} />
      ) : (
        <CheckboxIcon color={color} />
      )}
      <span style={style}>{text}</span>
    </label>
  );
};

export default Checkbox;
