import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { JSON_DATA, JSON_TEXT } from '../../constants';
import {
  SolutionDataType,
  SolutionTextType,
  StatisticDataType,
  VerticalTextType
} from '../../data/types';
import Button from '../../components/Button';
import Hotspot from '../../components/Hotspot';
import Statistic from '../../components/Statistic';
import NavMenu from '../../components/NavMenu';
import AudioPlayer from '../../components/AudioPlayer';
import Solution from './Solution';
import { useLang } from '../../contexts/LangContext';
import { useLangQuery } from '../../hooks/useLangQuery';
import VideoHelper from './VideoHelper';

const Vertical = () => {
  const [selectedSolutionKey, setSelectedSolutionKey] =
    useState<string | undefined>(undefined); // Note: undefined set here to not load hotspot when user directly loads to solution ex /retail/cx
  const [solutionKeyValid, setSolutionKeyValid] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(true); // video pause state
  const [isMute, setIsMute] = useState<boolean>(false); // video mute state
  const [interstitial, setInterstitial] = useState<string>('');
  const history = useHistory();

  const { verticalParam, solutionParam } =
    useParams<{
      verticalParam: string;
      solutionParam: string;
    }>();

  const langContext = useLang();
  // Note: not sure if this is the optimal way of reusing useEffect
  useLangQuery();

  useEffect(() => {
    // create solution key with <verticalParam>-<solutionParam> to match with key in data json
    // ex retail-cx
    let solutionParamKey = solutionParam
      ? verticalParam + '-' + solutionParam
      : '';
    setSelectedSolutionKey(solutionParamKey);
    // check if solutionParam exists and solutionParamKey is in data json
    if (solutionParam && !(solutionParamKey in JSON_DATA.scene)) {
      console.error(
        `Error: solution ${solutionParamKey} does not exist in data`
      );
      setSolutionKeyValid(false);
    } else {
      // if solutionParam is undefined set valid false
      setSolutionKeyValid(solutionParam ? true : false);

      // reset pause state
      setIsPlaying(true);
    }
  }, [verticalParam, solutionParam]);

  // do not load if langContext is undefined
  if (!langContext) {
    return null;
  }

  // check if verticalParam is in data json
  if (!(verticalParam in JSON_DATA.vertical)) {
    console.error(`Error: vertical ${verticalParam} does not exist in data`);

    // no vertical, return null
    return null;
  }

  // vertical text and datas
  const verticalData: Record<string, any> = JSON_DATA.vertical[verticalParam];
  const challenges: string[] = verticalData.challenges;
  const solutions: string[] = verticalData.solutions;
  const statistics: string[] = verticalData.statistics;
  const verticalTextData: Record<string, VerticalTextType> =
    JSON_TEXT[langContext.lang].vertical[verticalParam];

  //solution text and datas
  const solutionData: SolutionDataType | undefined =
    selectedSolutionKey && solutionKeyValid
      ? JSON_DATA.scene[selectedSolutionKey]
      : undefined;
  const solutionTextData: SolutionTextType | undefined =
    selectedSolutionKey && solutionKeyValid
      ? JSON_TEXT[langContext.lang].scene[selectedSolutionKey]
      : undefined;

  // src path from videoBg
  const videoBgSrcPath = solutionData
    ? solutionData.videoBg
    : verticalData.videoBg;

  // src path from audio
  const soundId = solutionData ? solutionData.audio : verticalData.audio;
  const soundFillerId = solutionData
    ? solutionData.audioFiller
    : verticalData.audioFiller;

  // solution show condition
  const showSolution =
    selectedSolutionKey !== '' &&
    solutionData &&
    solutionTextData &&
    interstitial === '';

  // back button show condition
  const showBackButton = interstitial === '';

  // Removing this
  // const showHotSpot = interstitial === '' && selectedSolutionKey === '';
  const showHotSpot = false; // Interim removal as it's swapped with stats
  const showStats = interstitial === '' && selectedSolutionKey === '';

  let audio = new Audio(process.env.PUBLIC_URL + JSON_DATA.audio.buttonPress);

  const onBackClick = () => {
    // do nothing if interstiual isn't default

    if (interstitial !== '') {
      return null;
    }

    // change depending if solution is chosen or not
    if (selectedSolutionKey === '') {
      setInterstitial('');

      // if single instance view, push to home
      if (JSON_DATA.view.length === 1) {
        history.push('/' + history.location.search);
      } else {
        // no solution so back to view
        history.push('/view' + history.location.search);
      }
    } else {
      // find interstitial to home -OLD
      /*
      let thisInterstitial =
        solutionData && solutionData.interstitial
          ? solutionData.interstitial.home
          : '';
      */
      // Instead of above put posterPath
      // path of home poster
      const posterPath = JSON_DATA.vertical[verticalParam].poster;
      setInterstitial(posterPath);

      // push history
      history.push(`/view/${verticalParam}` + history.location.search);
    }
  };

  const onSolutionClick = ({ link, key }: { link: string; key: string }) => {
    // do nothing if interstitial isn't default

    if (interstitial !== '') {
      return null;
    }

    /* -OLD
    const inHome = selectedSolutionKey === '';

    // find interstitial
    let thisInterstitial = inHome
      ? verticalData.interstitial[key]
      : solutionData?.interstitial[key];
    */
    // Instead of above put posterPath
    // path of poster
    const posterPath = JSON_DATA.scene[key].poster;
    setInterstitial(posterPath);
    // push history
    history.push(link + history.location.search);
  };

  const sceneId = verticalParam + '-' + solutionParam;
  return (
    <div className="vertical">
      <div className="statistic">
        {showStats &&
          statistics.map((id: string, index: number) => {
            const statisticObj: StatisticDataType = JSON_DATA.statistic[id];
            return (
              <Statistic
                key={id}
                count={index}
                total={statistics.length}
                content={JSON_TEXT[langContext.lang].statistic[id].description}
                x={statisticObj.boxPositionX}
                y={statisticObj.boxPositionY}
                width={statisticObj.boxWidth}
              />
            );
          })}
      </div>
      {showHotSpot &&
        solutions.map((id: string) => {
          const solutionObj: SolutionDataType = JSON_DATA.scene[id];
          return (
            <Hotspot
              key={id}
              x={Number(solutionObj.hotspotPositionX)}
              y={Number(solutionObj.hotspotPositionY)}
              onClick={() =>
                history.push(solutionObj.link + history.location.search)
              }
            />
          );
        })}
      {showSolution && (
        <Solution
          verticalId={verticalParam}
          sceneId={sceneId}
          solutionData={solutionData}
          solutionTextData={solutionTextData}
          isPlaying={isPlaying}
          togglePlay={() => {
            audio.play();
            setIsPlaying(!isPlaying);
          }}
        />
      )}
      {showBackButton && (
        <div className="vertical-back-button">
          <Button
            text={JSON_TEXT[langContext.lang].general.back}
            color="black"
            backgroundColor="transparent"
            width="133px"
            height="79px"
            textSize="80px"
            borderSize="0"
            onClick={onBackClick}
          />
        </div>
      )}
      <div className="vertical-video-bg">
        <VideoHelper
          isMute={isMute}
          videoBgSrcPath={videoBgSrcPath}
          posterPath={interstitial}
          isPlaying={isPlaying}
          onEnded={() => setInterstitial('')}
        />
      </div>
      <div className="vertical-solution-menu-container">
        <NavMenu
          disabled={!showBackButton}
          verticalTextData={verticalTextData}
          challenges={challenges}
          solutions={solutions}
          onClick={onSolutionClick}
          selectedSolutionKey={selectedSolutionKey}
        />
      </div>
      <div className="mute-control">
        <AudioPlayer
          soundId={soundId}
          soundFillerId={soundFillerId}
          isMute={isMute}
          onClick={() => {
            setIsMute(!isMute);
          }}
        />
      </div>
    </div>
  );
};

export default Vertical;
