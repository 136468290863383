import ReactDOM from 'react-dom';
import { HashRouter, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import View from './pages/View';
import Vertical from './pages/Vertical';
import { LangProvider } from './contexts/LangContext';
import { PlayStateProvider } from './contexts/PlayStateContext';
import Compose from './utils/Compose';
import './styles/index.scss';

ReactDOM.render(
  <Compose components={[LangProvider, PlayStateProvider]}>
    <HashRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/view" exact component={View} />
        <Route
          path="/view/:verticalParam/:solutionParam?"
          component={Vertical}
        />
      </Switch>
    </HashRouter>
  </Compose>,
  document.getElementById('root')
);
