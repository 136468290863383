import { useLang } from '../../contexts/LangContext';
import styled, { keyframes } from 'styled-components';
import SolutionLabel from '../../components/SolutionLabel';

type SolutionLabelGroupProps = {
  deploymentSelection?: any;
  verticalId: string;
  pause: boolean;
};

type LabelProps = {
  pause: boolean;
  delay: string;
};

// Logic on wait cycle from: https://waitanimate.wstone.io/
const labelCycleWait = keyframes`
  0% {
    transform: translateX(20px);
    opacity: 0;
  }
  4.545% {
    transform: translateX(-20px);
    opacity: 1;
  }
  9.091% {
    opacity: 1;
  }
  13.636% {
    transform: translateX(-30px);
    opacity: 1;
  }
  18.182% {
    transform: translateX(-40px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }`;

const StoreLabel = styled.div<LabelProps>`
  position: absolute;
  left: 1150px;
  top: 150px;
  z-index: 100;
  opacity: 0;
  animation-name: ${labelCycleWait};
  animation-timing-function: ease-out;
  animation-duration: 22.5s;
  animation-iteration-count: infinite;
  animation-delay: ${(prop) => prop.delay};
  animation-play-state: ${(prop) => (prop.pause ? 'paused' : 'running')};
`;

const SolutionLabelGroup = ({
  deploymentSelection,
  verticalId,
  pause
}: SolutionLabelGroupProps) => {
  const langContext = useLang();
  if (!langContext) {
    return null;
  }
  return (
    <>
      <StoreLabel pause={pause} delay="0s">
        <SolutionLabel
          deploymentSelection={deploymentSelection}
          verticalId={verticalId}
        />
      </StoreLabel>
      <StoreLabel pause={pause} delay="5.5s">
        <SolutionLabel verticalId={verticalId} />
      </StoreLabel>
      <StoreLabel pause={pause} delay="11s">
        <SolutionLabel verticalId={verticalId} />
      </StoreLabel>
      <StoreLabel pause={pause} delay="16.5s">
        <SolutionLabel verticalId={verticalId} />
      </StoreLabel>
    </>
  );
};

export default SolutionLabelGroup;
